<template>
  <div>
    <BreadCrumbs v-if="breadcrumbs" :breadcrumbs="breadcrumbs" />

    <v-card>
      <v-card-title>BUSCADOR</v-card-title>
      <v-card-text>
        <v-row>
          <v-col class="d-flex flex-row">
            <v-text-field
              v-model="filters.search"
              clearable
              placeholder="Busca por nome da sala"
            ></v-text-field>
            <v-btn class="mx-2 my-3" color="primary" @click="loadRooms()">
              <v-icon>mdi-magnify</v-icon>
            </v-btn>
            <v-btn @click="clearFilters()" class="mx-2 my-3">Limpar</v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <!-- <v-expansion-panels>
      <v-expansion-panel>
        <v-expansion-panel-header> BUSCADOR </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row>
            <v-col class="d-flex flex-row">
              <v-text-field
                v-model="filters.search"
                clearable
                placeholder="Busca por nome da sala"
              ></v-text-field>
              <v-btn class="mx-2 my-3" color="primary" @click="loadRooms()">
                <v-icon>mdi-magnify</v-icon>
              </v-btn>
              <v-btn @click="clearFilters()" class="mx-2 my-3">Limpar</v-btn>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels> -->

    <v-card class="mt-2">
      <v-card-title>
        Administração de salas {{ formattedInstitutionName }}
      </v-card-title>

      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="rooms"
          disable-pagination
          hide-default-footer
          :loading="loading"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-spacer></v-spacer>
              <v-btn
                rounded
                small
                color="success"
                @click="redirectToCreateRoomPage()"
                >Nova sala +</v-btn
              >
            </v-toolbar>
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <v-btn
              class="mr-2"
              title="Editar"
              @click="redirectToUpdateRoomPage(item.id)"
              icon
              color="primary"
            >
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
            <v-btn
              class="mr-2"
              title="Ver debates"
              @click="redirectToGamesOfThisRoomPage(item.id)"
              icon
              color="primary"
            >
              φ
            </v-btn>
            <v-btn
              class="mr-2"
              title="Deletar"
              @click="deleteRoom(item.id)"
              icon
              color="primary"
            >
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <div class="text-center mt-2">
      <v-pagination
        v-model="page"
        :length="numberOfPages"
        @input="loadRooms()"
      ></v-pagination>
    </div>
  </div>
</template>

<script>
import errorHandler from "@/helpers/error_handler";
import BreadCrumbs from "@/components/template/Breadcrumbs.vue";

export default {
  components: {
    BreadCrumbs,
  },
  props: {
    breadcrumbs: {
      type: Array,
      default: null,
    },
  },
  computed: {
    numberOfPages() {
      return Math.ceil(this.totalRows / this.resultsPerPage);
    },
    formattedInstitutionName() {
      return this.institutionName ? `(${this.institutionName})` : "";
    },
    institutionId() {
      return this.$route.params.id;
    },
  },
  data: () => ({
    headers: [
      { text: "Nome da sala", value: "description" },
      { text: "Ações", value: "actions" },
    ],
    loading: false,
    rooms: [],
    resultsPerPage: 20,
    totalRows: 0,
    page: 1,
    institutionName: null,
    filters: {
      currentPage: 1,
      search: null,
    },
  }),
  methods: {
    async loadRooms(page) {
      try {
        this.loading = true;

        let currentPage = page ? page : 1;

        let url = `/admin/instituicoes/${this.institutionId}/salas/buscar`;

        const response = await this.$axios.get(url, {
          params: {
            search: this.filters.search,
            pagination: 1,
            resultsPerPage: this.resultsPerPage,
            page: currentPage,
          },
        });

        this.setRooms(response.data);
      } catch (error) {
        this.handleErrors(error);
      } finally {
        this.loading = false;
      }
    },
    setRooms(data) {
      const cpData = { ...data };

      this.totalRows = cpData.count;

      this.rooms = cpData.rows.map((u) => {
        return { ...u };
      });

      this.institutionName = cpData.institutionName;
    },
    async deleteRoom(id) {
      try {
        const confirm = await this.$root.$confirm(
          "Atenção",
          `Deseja remover esta sala do sistema?`
        );

        if (!confirm) return;

        this.loading = true;

        let url = `/admin/instituicoes/salas/${id}`;

        await this.$axios.delete(url);

        this.deleteRoomFromTable(id);
      } catch (error) {
        this.handleErrors(error);
      } finally {
        this.loading = false;
      }
    },
    deleteRoomFromTable(id) {
      const index = this.rooms.findIndex((p) => p.id === id);

      this.rooms.splice(index, 1);
    },
    redirectToCreateRoomPage() {
      this.$router.push(
        `/admin/instituicoes/${this.institutionId}/salas/cadastrar`
      );
    },
    redirectToUpdateRoomPage(roomId) {
      this.$router.push(
        `/admin/instituicoes/${this.institutionId}/salas/${roomId}/editar`
      );
    },
    redirectToGamesOfThisRoomPage(id) {
      this.$router.push(`/salas/${id}`);
    },
    clearFilters() {
      this.filters = {
        currentPage: 1,
        search: null,
      };

      this.loadRooms();
    },
    handleErrors(errors) {
      console.error(errors);

      const errorHandled = errorHandler.treatError(errors);

      if (Array.isArray(errorHandled))
        errorHandled.map((e) => this.$toast.error(e));
      else this.$toast.error(errorHandled);
    },
  },
  created() {
    this.loadRooms();
  },
};
</script>

<style></style>
